.t-banner-media {
    position: relative;
    overflow: hidden;
    min-height: (578/760*100+vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 10px;
    background-color: #000000;
    color: #ffffff;

    @include breakpoint-up(md) {
        margin-bottom: 20px;
        min-height: 500px;
    }

    &__video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &::before {
            content: "";
            display: block;
            background-color: rgba($color: #000000, $alpha: 0.63);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: relative;
        z-index: 2;
        @include padding-x;
        padding-top: 28px;
        padding-bottom: 28px;

        @include breakpoint-up(md) {
            padding-top: 82px;
            padding-bottom: 82px;
        }
    }

    &__title {
        max-width: 560px;
        font-size: 38px;
        line-height: 1;
        margin-bottom: 30px;
        // word-break: break-all;

        @include breakpoint-up(lg) {
            font-size: 76px;
            margin-bottom: 50px;
        }
    }

    &__description {
        max-width: 217px;
        font-size: 24px;
    }

    &__more {
        .t-btn-link {
            margin-left: 30px;
            margin-top: 12px;

            @include breakpoint-up(sm) {
                margin-top: 0;
            }
        }
    }
}

.t-block-cta {
    @include padding-x;
    padding-top: 40px;
    padding-bottom: 45px;
    background-color: #000000;
    color: #ffffff;
    margin-bottom: 10px;

    @include breakpoint-up(md) {
        margin-bottom: 20px;
    }

    &__header {
        @include breakpoint-down(md) {
            margin-bottom: 60px;
        }
    }

    &__wrapper {
        @include breakpoint-up(md) {
            max-width: 836px;
        }
    }

    &__content {
        font-size: 28px;

        @include breakpoint-up(lg) {
            font-size: 32px;
        }

        h2 {
            font-size: 32px;
            margin-bottom: 20px;

            @include breakpoint-up(lg) {
                font-size: 48px;
            }
        }

        @include clear-p;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__description {
        font-size: 14px;
        margin-bottom: 30px;

        @include breakpoint-up(md) {
            max-width: 217px;
            margin-top: 24px;
        }
    }

    &__call {
        margin-top: auto;
    }

    &__more {
        margin-top: 20px;
    }

    &--wide {
        background-color: $light !important;
        color: $body-color !important;

        .t-block-cta {
            &__wrapper {
                @include breakpoint-up(lg) {
                    max-width: 920px;
                    margin: 0 auto;
                }
            }

            &__content {
                h2 {
                    color: var(--page-color);
                }
            }

            &__more {
                margin-top: 60px;
                text-align: center;
            }
        }
    }

    &--dynamic {
        background-color: var(--page-color);
    }
}

.t-block-text {
    margin: 43px 0 55px;
    @include padding-x;
    font-size: 28px;
    line-height: 1.08;

    @include breakpoint-up(lg) {
        font-size: 32px;
    }

    a {
        text-decoration: underline;

        &:hover {
            color: $pink;
        }
    }
}

.t-banner-normal {
    @include padding-x;
    padding-top: 48px;
    padding-bottom: 48px;
    color: #ffffff;
    margin-bottom: 10px;
    min-height: (537/760*100+vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include breakpoint-up(md) {
        min-height: 460px;
        margin-bottom: 20px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;

        @include breakpoint-up(lg) {
            max-width: 483px;
        }
    }

    &__content {
        @include breakpoint-up(lg) {
            transition: all 0.4s cubic-bezier(0.5, 1, 0.89, 1);
        }
    }

    &__logo {
        margin-bottom: 32px;

        img {
            height: 74px;
        }
    }

    &__description {
        font-size: 19px;

        @include breakpoint-up(lg) {
            font-size: 24px;
        }
    }

    &__more {
        margin-top: 35px;

        @include breakpoint-up(lg) {
            margin-top: 90px;

            &:hover  + div {
                transform: translate(0, 30px);
            }
        }
    }
}

.t-diagram {
    padding-top: 66px;
    padding-bottom: 83px;
    background-color: $pink;

    &__header {
        @include padding-x;
        margin-bottom: 62px;

        @include breakpoint-up(xl) {
            max-width: 940px;
        }
    }

    &__title {
        font-size: 32px;
        margin-bottom: 16px;
    }

    &__description {
        font-size: 24px;
    }

    &__body {
        @include padding-x;
        position: relative;

        @include breakpoint-down(lg) {
            max-width: 355px;
            margin-left: auto;
            margin-right: auto;
        }

        .row {
            @include gutters(60px);

            & + .row {
                @include breakpoint-down(lg) {
                    margin-top: 23px;
                }

                .t-diagram-card {
                    @include breakpoint-up(lg) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.t-diagram-card {
    position: relative;
    margin-bottom: 60px;

    @include breakpoint-down(lg) {
        width: 184px;
        margin-left: 22px;
        margin-bottom: 30px;
    }

    &__inner {
        background-color: $pink;
        border: thin solid #000000;
        border-radius: 27px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
        overflow: hidden;
        height: 327px;
        padding: 40px 20px;

        @include breakpoint-down(lg) {
            height: 100px;
            border-radius: 23px;
            padding: 10px 20px;
            justify-content: center;
        }
    }

    &__header {
        flex: 1;

        @include breakpoint-down(lg) {
            flex: 0 0 auto;
        }

        figure {
            margin-bottom: 18px;

            @include breakpoint-down(lg) {
                margin-bottom: 9px;
            }

            img {
                max-height: 35px;

                @include breakpoint-down(lg) {
                    max-height: 26px;
                }
            }
        }

        p {
            font-size: 18px;
            margin-bottom: 0;

            @include breakpoint-down(lg) {
                font-size: 14px;
            }

            span {
                @include breakpoint-up(lg) {
                    display: none;
                }
            }
        }
    }

    &__footer {
        @include breakpoint-down(lg) {
            display: none;
        }
    }

    &--root {
        @include breakpoint-down(lg) {
            width: 260px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 66px;

            .t-diagram-card__inner {
                height: 140px;
            }
        }
    }

    &--small {
        @include breakpoint-down(lg) {
            width: 197px;
            margin-left: auto;
        }

        .t-diagram-card__inner {
            height: 165px;
            justify-content: center;

            @include breakpoint-down(lg) {
                height: 90px;
            }

            h3 {
                font-size: 32px;
                position: relative;
                z-index: 2;
                margin-bottom: 0;

                @include breakpoint-down(lg) {
                    font-size: 24px;
                }
            }

            &.lazy {
                border: none;
                background-repeat: no-repeat;
                background-size: cover;
                color: #ffffff;
                background-position: 50% 50%;

                &::before {
                    content: "";
                    display: block;
                    background-color: #000000;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0.52;
                }
            }
        }
    }
}

.t-diagram-information {
    @include breakpoint-up(lg) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    ul {
        @include reset-list;
        font-size: 14px;
        max-width: 125px;

        li {
            display: flex;
            align-items: center;
            padding: 5px 0;

            &:not(:last-child) {
                border-bottom: thin solid rgba($color: #000000, $alpha: 0.2);
            }

            figure {
                margin-bottom: 0;
                margin-right: 20px;

                img {
                    width: 19px;
                }
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .t-btn {
        margin-top: 40px;

        @include breakpoint-up(lg) {
            margin-top: auto;
        }

        @media (min-width: 991px) and (max-width: 1400px) {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 16px;

            svg[role="img"] {
                margin-left: 10px;
            }
        }
    }
}

.t-diagram-path {
    position: relative;

    @include breakpoint-down(lg) {
        display: none;
    }

    img {
        position: absolute;
        top: -13px;
        left: -13px;
        height: 27px;
    }

    &--1 {
        width: (1042/1280*100%);
        margin: 0 auto;
    }

    &--2 {
        width: 39px;
        position: absolute;
        top: 100%;
        left: (104/276*100%);
    }

    &--3 {
        width: 175px;
        position: absolute;
        top: calc(100% - 27px);
        right: calc(100% - 27px);
    }

    &--4 {
        width: 88px;
        position: absolute;
        top: 100%;
        right: 0;
    }

    &--5 {
        width: 128px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.t-diagram-line-mobile {
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 664px;

    img {
        position: absolute;
        top: -10px;
        left: -10px;
    }

    svg {
        height: 100%;
    }
}

.t-diagram-arrow {
    position: absolute;
    top: calc(100% + 15px);
    right: 48px;

    @include breakpoint-up(lg) {
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
    }
}