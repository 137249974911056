/*---------------------------------
**** Global | Layout
-----------------------------------*/
:root {
    --safe-area-bottom: calc(100vh - 100 * var(--vh));
    --page-color: #000000;
}

.page-template-chalupa {
    --page-color: #{$green};
}

.page-template-difdoner {
    --page-color: #{$brown};
}

* {
    &:focus {
        outline: none;
    }
}

body {
    font-family: $font-family-primary;
    color: $body-color;
    position: relative;
    line-height: 1.3;

    &.is-lock {
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}

.container-fluid {
    padding-left: 10px;
    padding-right: 10px;

    @include breakpoint-up(md) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.t-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    overflow: hidden;

    @include ie11 {
        display: block;
    }
}

.t-content {
    flex: 1 1 auto;
}

.t-popup-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.65;
    z-index: 99;
}

/*---------------------------------
**** Global | Link
-----------------------------------*/
a {
    color: inherit;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

/*---------------------------------
**** Global | Media
-----------------------------------*/
img {
    max-width: 100%;

    &.lazy {
        visibility: hidden;

        &.loaded {
            visibility: visible;
        }
    }
}

.t-img-drop,
.t-img-drop-contain {
    display: block;
    position: relative;
    overflow: hidden;

    img {
        display: block;
        width: 100%;
        height: 100%;
		object-position: 50% 50%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.t-img-drop {
    img {
        object-fit: cover;
    }
}

.t-img-drop-contain {
    img {
        object-fit: contain;
    }
}

/*---------------------------------
**** Global | Forms
-----------------------------------*/
input,
textarea {
    font-size: 16px;
}

textarea {
    resize: none;
}