.t-block-contact {
    @include padding-x;
    padding-top: 58px;
    padding-bottom: 35px;
    background-color: $light;

    @include breakpoint-up(md) {
        padding-top: 66px;
    }

    h5 {
        font-size: inherit;
        margin-bottom: 0;
    }

    section {
        font-size: 28px;

        @include breakpoint-up(lg) {
            font-size: 32px;
        }

        h5 {
            color: $pink;
        }

        @include clear-p;

        & + section {
            margin-top: 48px;

            @include breakpoint-up(lg) {
                margin-top: 106px;
            }
        }
    }

    &__row {
        @include breakpoint-up(lg) {
            @include gutters(60px);
        }
    }

    &__header {
        display: flex;
        flex-direction: column;

        @include breakpoint-down(md) {
            margin-bottom: 50px;
        }
    }

    &__body {
        section {
            @include breakpoint-up(md) {
                .page-template-contact & {
                    margin-bottom: 60px;
                }
            }
        }
    }
}

.t-copyright {
    @include lh-small;
    margin-top: auto;
    padding: 10px 0;
}