.t-banner-wide {
    @include padding-x;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: (496/760*100+vh);
    overflow: hidden;
    color: #ffffff;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint-up(md) {
        min-height: 396px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &::before {
        content: "";
        display: block;
        background-color: #000000;
        opacity: 0.4;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__wrapper {
        max-width: 692px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
    }

    &__logo {
        margin-bottom: 10px;

        img {
            height: 74px;
        }
    }

    &__description {
        font-size: 28px;

        @include breakpoint-up(lg) {
            font-size: 32px;
        }
    }
}

.t-group-buttons {
    margin: 40px 0;
    text-align: center;

    @include breakpoint-up(lg) {
        margin: 58px 0;
    }

    .t-btn {
        &:not(:last-child) {
            margin-bottom: 15px;

            @include breakpoint-up(md) {
                margin-bottom: 0;
                margin-right: 36px;
            }
        }
    }

    .t-btn-primary {
        background-color: var(--page-color);
    }

    .t-btn-link {
        color: var(--page-color);
    }
}

.t-block-content {
    @include padding-x;
    margin: 65px 0 47px;
    font-size: 18px;

    @include breakpoint-up(lg) {
        margin: 74px 0 113px;
        font-size: 24px;
    }

    &__wrapper {
        @include breakpoint-up(lg) {
            max-width: 920px;
            margin: 0 auto;
        }
    }

    h2 {
        font-size: 28px;
        color: var(--page-color);
        margin-bottom: 22px;

        @include breakpoint-up(lg) {
            font-size: 32px;
        }
    }

    @include clear-p;
}

.t-gallery {
    padding: 0 20px;
    margin: 47px 0 75px;

    @include breakpoint-up(md) {
        padding: 0 $px;
    }

    @include breakpoint-up(lg) {
        margin: 113px 0 100px;
    }
}

.t-gallery-list {
    margin-bottom: -30px;
    justify-content: center;

    @include breakpoint-up(lg) {
        @include gutters(60px);
        margin-bottom: -60px;
    }

    &__item {
        margin-bottom: 30px;

        @include breakpoint-up(lg) {
            margin-bottom: 60px;
        }

        figure {
            margin-bottom: 0;
            @include img-drop(276, 327);
            border-radius: 18px;

            @include breakpoint-up(lg) {
                border-radius: 27px;
            }
        }

        &:nth-child(5n + 1) {
            flex: 0 0 100%;
            max-width: 100%;

            @include breakpoint-up(md) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            figure {
                padding-bottom: (169/315*100%);

                @include breakpoint-up(md) {
                    padding-bottom: 0;
                    height: 100%;
                }
            }
        }
    }
}