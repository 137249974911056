@font-face {
    font-family: "HK Grotesk";
    src: url("../fonts/HKGrotesk-Regular.woff2") format("woff2"), url("../fonts/HKGrotesk-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "HK Grotesk";
    src: url("../fonts/HKGrotesk-Italic.woff2") format("woff2"), url("../fonts/HKGrotesk-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "HK Grotesk";
    src: url("../fonts/HKGrotesk-Bold.woff2") format("woff2"), url("../fonts/HKGrotesk-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "HK Grotesk";
    src: url("../fonts/HKGrotesk-BoldItalic.woff2") format("woff2"), url("../fonts/HKGrotesk-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
}
