/*---------------------------------
**** Objects | Type
-----------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

h1,
h2,
h3 {
    line-height: 1.08;
}