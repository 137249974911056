$icon-toggler-size: 29px;
$icon-toggler-weight: 5px;
$icon-toggler-color: $body-color;
$icon-toggler-top: 3px;
$icon-toggler-bottom: $icon-toggler-size - $icon-toggler-top - $icon-toggler-weight;
$icon-toggler-transition: all 0.2s $easeInOutQuad;

.t-icon-toggler {
    display: inline-flex;
    align-items: center;
    width: $icon-toggler-size;
    height: $icon-toggler-size;
    position: relative;
    color: $icon-toggler-color;

    &::before,
    &::after {
        content: "";
        display: block;
        height: $icon-toggler-weight;
        background-color: currentColor;
        position: absolute;
        transition: $icon-toggler-transition;
    }

    &::before {
        width: 23px;
        right: 0;
        top: $icon-toggler-top;

        .t-navbar-toggler.is-active & {
            width: 100%;
            top: 50%;
            transform: rotate(45deg);
            margin-top: (-$icon-toggler-weight / 2);
        }
    }

    &::after {
        width: 11px;
        right: 0;
        top: $icon-toggler-bottom;

        .t-navbar-toggler.is-active & {
            width: 100%;
            top: 50%;
            transform: rotate(-45deg);
            margin-top: (-$icon-toggler-weight / 2);
        }
    }

    span {
        display: block;
        width: 100%;
        height: $icon-toggler-weight;
        background-color: $icon-toggler-color;
        transition: $icon-toggler-transition;

        .t-navbar-toggler.is-active & {
            opacity: 0;
        }
    }
}

.t-header {
    height: 65px;
}

.t-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #ffffff;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
    }
}

.t-navbar-toggler {
    @include clear-button;
    @include breakpoint-up(lg) {
        display: none;
    }
}

.t-navbar-brand {
    @include breakpoint-up(xl) {
        width: 340px;
    }

    img {
        height: 50px;
    }
}

.t-navbar-nav {
    @include reset-list;
    @include breakpoint-up(lg) {
        display: flex;
    }

    li {
        a {
            display: inline-block;
            padding: 6px 0;
            font-size: 32px;
            @include lh-small;
            letter-spacing: 0.1em;
            position: relative;

            @include breakpoint-up(lg) {
                font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
            }

            &::after {
                content: "";
                display: block;
                border-bottom: 2px solid currentColor;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                transition: all 0.3s $easeInOutQuad;
            }
        }

        &:not(:last-child) {
            margin-bottom: 40px;

            @include breakpoint-up(lg) {
                margin-bottom: 0;
                margin-right: 15px;
            }

            @include breakpoint-up(xl) {
                margin-right: 30px;
            }
        }

        &:hover,
        &.is-active {
            a {
                &::after {
                    width: 100%;
                }
            }
        }
    }
}

.t-navbar-collapse {
    transition: all 0.2s ease;

    @include breakpoint-down(lg) {
        position: fixed;
        top: 65px;
        left: 0;
        right: 0;
        background-color: #ffffff;
        padding: 40px 10px;
        text-align: center;
        z-index: 999;

        &:not(.is-show) {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.t-navbar-links {
    margin-top: 30px;

    @include breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0;
    }

    @include breakpoint-up(xl) {
        width: 340px;
    }
}

.t-socials {
    @include reset-list;
    display: inline-flex;
    font-size: 0;

    li {
        &:not(:last-child) {
            margin-right: 24px;
        }

        a {
            display: block;

            @include icon-size(18px);
        }
    }
}

.t-btn-contact {
    margin-top: 38px;

    @include breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 15px;
    }

    @include breakpoint-up(xl) {
        margin-left: 40px;
    }

    .t-btn {
        padding-top: 7px;
        padding-bottom: 7px;
    }
}
