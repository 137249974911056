@font-face {
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Regular.woff2") format("woff2"), url("../fonts/HKGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Italic.woff2") format("woff2"), url("../fonts/HKGrotesk-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-Bold.woff2") format("woff2"), url("../fonts/HKGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "HK Grotesk";
  src: url("../fonts/HKGrotesk-BoldItalic.woff2") format("woff2"), url("../fonts/HKGrotesk-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
/* ==========================================================================
## GLOBAL
========================================================================== */
/*---------------------------------
**** Global | Layout
-----------------------------------*/
:root {
  --safe-area-bottom: calc(100vh - 100 * var(--vh));
  --page-color: #000000;
}

.page-template-chalupa {
  --page-color: #73b53d;
}

.page-template-difdoner {
  --page-color: #5c311d;
}

*:focus {
  outline: none;
}

body {
  font-family: "HK Grotesk", sans-serif;
  color: #000000;
  position: relative;
  line-height: 1.3;
}
body.is-lock {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.container-fluid {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 768px) {
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.t-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}
@media all and (-ms-high-contrast: none) {
  .t-container {
    display: block;
  }
}

.t-content {
  flex: 1 1 auto;
}

.t-popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.65;
  z-index: 99;
}

/*---------------------------------
**** Global | Link
-----------------------------------*/
a {
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

/*---------------------------------
**** Global | Media
-----------------------------------*/
img {
  max-width: 100%;
}
img.lazy {
  visibility: hidden;
}
img.lazy.loaded {
  visibility: visible;
}

.t-img-drop,
.t-img-drop-contain {
  display: block;
  position: relative;
  overflow: hidden;
}
.t-img-drop img,
.t-img-drop-contain img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.t-img-drop img {
  object-fit: cover;
}

.t-img-drop-contain img {
  object-fit: contain;
}

/*---------------------------------
**** Global | Forms
-----------------------------------*/
input,
textarea {
  font-size: 16px;
}

textarea {
  resize: none;
}

/*---------------------------------
**** Objects | Buttons
-----------------------------------*/
.t-btn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  background-color: transparent;
  border: thin solid transparent;
  border-radius: 60px;
  padding: 10px 30px;
  transition: all 0.15s ease-in-out;
  appearance: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.06;
  letter-spacing: 0.105em;
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
}
.t-btn svg[role=img] {
  width: 10px;
  height: 10px;
  margin-left: 50px;
  transition: all 0.15s ease-in-out;
}
.t-btn:hover {
  color: #fff;
}
.t-btn:hover svg[role=img] {
  transform: rotate(180deg);
}

.t-btn-white:disabled, .t-btn-outline-primary:disabled, .t-btn-primary:disabled, .disabled.t-btn-white, .disabled.t-btn-outline-primary, .disabled.t-btn-primary {
  background-color: #ced4da;
}
.t-btn-white:disabled:hover, .t-btn-outline-primary:disabled:hover, .t-btn-primary:disabled:hover, .disabled.t-btn-white:hover, .disabled.t-btn-outline-primary:hover, .disabled.t-btn-primary:hover {
  background-color: #ced4da;
}

.t-btn-link:disabled, .disabled.t-btn-link {
  color: #ced4da;
}
.t-btn-link:disabled:hover, .disabled.t-btn-link:hover {
  color: #ced4da;
}

.t-btn-block {
  width: 100%;
}

.t-btn-link {
  border-width: 0 0 2px;
  border-color: currentColor;
  border-radius: 0;
  padding: 5px 0;
  font-weight: normal;
  text-transform: none;
  color: inherit;
}
.t-btn-link:hover {
  color: inherit;
}

.t-btn-primary {
  background-color: #000000;
}
.t-btn-primary:hover {
  background-color: #000000;
}

.t-btn-outline-primary {
  border-color: #000000;
  color: #000000;
}
.t-btn-outline-primary:hover {
  background-color: #000000;
  color: #fff;
}

.t-btn-white {
  background-color: #ffffff;
  color: #000000;
}
.t-btn-white:hover {
  background-color: #ffffff;
  color: #000000;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.t-form-control {
  display: block;
  width: 100%;
  appearance: none;
  background-color: transparent;
  border-width: 0 0 thin;
  border-color: #000000;
  border-style: solid;
  border-radius: 0;
  padding: 10px 0;
  font-size: 22px;
  line-height: 1.06;
}
.t-form-control::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.t-form-group {
  margin-bottom: 20px;
}

.t-custom-control {
  display: block;
  padding-left: 28px;
  position: relative;
  line-height: 1;
}
.t-custom-control label {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  vertical-align: middle;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
}
.t-custom-control label::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border: thin solid #000000;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.t-custom-control input {
  display: none;
}
.t-custom-control input[type=checkbox]:checked + label:before {
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.5L3.77551 6.33333L9 1' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-size: 12px auto;
}
.t-custom-control input[type=radio] + label::before {
  border-radius: 50%;
}
.t-custom-control input[type=radio]:checked + label:before {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='black'/%3E%3C/svg%3E%0A");
  background-size: 12px auto;
}
.t-custom-control a {
  color: #007bff;
}

/*---------------------------------
**** Objects | Icons
-----------------------------------*/
svg[role=img] {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  fill: currentColor;
}

.t-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  transition: all 0.2s ease;
}

.t-icon-minus,
.t-icon-plus {
  font-size: 7px;
  width: 1em;
  height: 1em;
}
.t-icon-minus::before,
.t-icon-plus::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: currentColor;
}

.t-icon-plus {
  position: relative;
}
.t-icon-plus::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: currentColor;
  position: absolute;
  top: 0;
  left: calc((1em - 1px) / 2);
}

.t-icon-remove {
  width: 1em;
  height: 1em;
  position: relative;
}
.t-icon-remove::before, .t-icon-remove::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  position: absolute;
  left: 0;
  top: 50%;
}
.t-icon-remove::before {
  transform: rotate(45deg);
}
.t-icon-remove::after {
  transform: rotate(-45deg);
}

/*---------------------------------
**** Objects | Loading
-----------------------------------*/
.t-loading {
  position: relative;
  color: transparent;
  pointer-events: none;
}
.t-loading:hover {
  color: transparent;
}
.t-loading::after {
  content: "";
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-image: url("../images/loading-light.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 1.5em auto;
  vertical-align: middle;
  position: absolute;
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
  mix-blend-mode: difference;
}

/*---------------------------------
**** Objects | Type
-----------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1,
h2,
h3 {
  line-height: 1.08;
}

/* ==========================================================================
## COMPONENTS
========================================================================== */
.t-block-contact {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 58px;
  padding-bottom: 35px;
  background-color: #f6f6f6;
}
@media (min-width: 768px) {
  .t-block-contact {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 768px) {
  .t-block-contact {
    padding-top: 66px;
  }
}
.t-block-contact h5 {
  font-size: inherit;
  margin-bottom: 0;
}
.t-block-contact section {
  font-size: 28px;
}
@media (min-width: 992px) {
  .t-block-contact section {
    font-size: 32px;
  }
}
.t-block-contact section h5 {
  color: #c01e64;
}
.t-block-contact section p:last-child {
  margin-bottom: 0;
}
.t-block-contact section + section {
  margin-top: 48px;
}
@media (min-width: 992px) {
  .t-block-contact section + section {
    margin-top: 106px;
  }
}
@media (min-width: 992px) {
  .t-block-contact__row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .t-block-contact__row > .col,
.t-block-contact__row > [class*=col-] {
    padding-right: 30px;
    padding-left: 30px;
  }
}
.t-block-contact__header {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767.98px) {
  .t-block-contact__header {
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) {
  .page-template-contact .t-block-contact__body section {
    margin-bottom: 60px;
  }
}

.t-copyright {
  line-height: 1.06;
  margin-top: auto;
  padding: 10px 0;
}

.t-footer {
  background-color: #f6f6f6;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 44px;
  padding-bottom: 44px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .t-footer {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 768px) {
  .t-footer {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
}
.page-template-contact .t-footer {
  background-color: transparent;
}

.t-footer-nav {
  list-style: none;
  padding-left: 0;
  margin: 0 -10px -34px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 1.06;
  white-space: nowrap;
}
.t-footer-nav li {
  padding: 0 10px;
  margin-bottom: 34px;
}

.t-form-contact__header {
  font-size: 28px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .t-form-contact__header {
    font-size: 32px;
    margin-bottom: 40px;
  }
}
.t-form-contact__header h5 {
  color: #007bff;
}
@media (max-width: 991.98px) {
  .t-form-contact__footer > div:not(:last-child) {
    margin-bottom: 37px;
  }
}
@media (max-width: 991.98px) {
  .t-form-contact__footer .t-btn {
    width: 100%;
  }
}

.t-icon-toggler {
  display: inline-flex;
  align-items: center;
  width: 29px;
  height: 29px;
  position: relative;
  color: #000000;
}
.t-icon-toggler::before, .t-icon-toggler::after {
  content: "";
  display: block;
  height: 5px;
  background-color: currentColor;
  position: absolute;
  transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.t-icon-toggler::before {
  width: 23px;
  right: 0;
  top: 3px;
}
.t-navbar-toggler.is-active .t-icon-toggler::before {
  width: 100%;
  top: 50%;
  transform: rotate(45deg);
  margin-top: -2.5px;
}
.t-icon-toggler::after {
  width: 11px;
  right: 0;
  top: 21px;
}
.t-navbar-toggler.is-active .t-icon-toggler::after {
  width: 100%;
  top: 50%;
  transform: rotate(-45deg);
  margin-top: -2.5px;
}
.t-icon-toggler span {
  display: block;
  width: 100%;
  height: 5px;
  background-color: #000000;
  transition: all 0.2s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.t-navbar-toggler.is-active .t-icon-toggler span {
  opacity: 0;
}

.t-header {
  height: 65px;
}

.t-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff;
}
.t-navbar__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}

.t-navbar-toggler {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  appearance: none;
}
@media (min-width: 992px) {
  .t-navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .t-navbar-brand {
    width: 340px;
  }
}
.t-navbar-brand img {
  height: 50px;
}

.t-navbar-nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
@media (min-width: 992px) {
  .t-navbar-nav {
    display: flex;
  }
}
.t-navbar-nav li a {
  display: inline-block;
  padding: 6px 0;
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: 0.1em;
  position: relative;
}
@media (min-width: 992px) {
  .t-navbar-nav li a {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.t-navbar-nav li a::after {
  content: "";
  display: block;
  border-bottom: 2px solid currentColor;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: all 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
}
.t-navbar-nav li:not(:last-child) {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .t-navbar-nav li:not(:last-child) {
    margin-bottom: 0;
    margin-right: 15px;
  }
}
@media (min-width: 1200px) {
  .t-navbar-nav li:not(:last-child) {
    margin-right: 30px;
  }
}
.t-navbar-nav li:hover a::after, .t-navbar-nav li.is-active a::after {
  width: 100%;
}

.t-navbar-collapse {
  transition: all 0.2s ease;
}
@media (max-width: 991.98px) {
  .t-navbar-collapse {
    position: fixed;
    top: 65px;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: 40px 10px;
    text-align: center;
    z-index: 999;
  }
  .t-navbar-collapse:not(.is-show) {
    opacity: 0;
    visibility: hidden;
  }
}

.t-navbar-links {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .t-navbar-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .t-navbar-links {
    width: 340px;
  }
}

.t-socials {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: inline-flex;
  font-size: 0;
}
.t-socials li:not(:last-child) {
  margin-right: 24px;
}
.t-socials li a {
  display: block;
}
.t-socials li a svg[role=img] {
  width: 18px;
  height: 18px;
}

.t-btn-contact {
  margin-top: 38px;
}
@media (min-width: 992px) {
  .t-btn-contact {
    margin-top: 0;
    margin-left: 15px;
  }
}
@media (min-width: 1200px) {
  .t-btn-contact {
    margin-left: 40px;
  }
}
.t-btn-contact .t-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}

/* ==========================================================================
## PAGES
========================================================================== */
.t-banner-wide {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 65.2631578947vh;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .t-banner-wide {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 768px) {
  .t-banner-wide {
    min-height: 396px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.t-banner-wide::before {
  content: "";
  display: block;
  background-color: #000000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.t-banner-wide__wrapper {
  max-width: 692px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.t-banner-wide__logo {
  margin-bottom: 10px;
}
.t-banner-wide__logo img {
  height: 74px;
}
.t-banner-wide__description {
  font-size: 28px;
}
@media (min-width: 992px) {
  .t-banner-wide__description {
    font-size: 32px;
  }
}

.t-group-buttons {
  margin: 40px 0;
  text-align: center;
}
@media (min-width: 992px) {
  .t-group-buttons {
    margin: 58px 0;
  }
}
.t-group-buttons .t-btn:not(:last-child) {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .t-group-buttons .t-btn:not(:last-child) {
    margin-bottom: 0;
    margin-right: 36px;
  }
}
.t-group-buttons .t-btn-primary {
  background-color: var(--page-color);
}
.t-group-buttons .t-btn-link {
  color: var(--page-color);
}

.t-block-content {
  padding-left: 20px;
  padding-right: 20px;
  margin: 65px 0 47px;
  font-size: 18px;
}
@media (min-width: 768px) {
  .t-block-content {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 992px) {
  .t-block-content {
    margin: 74px 0 113px;
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .t-block-content__wrapper {
    max-width: 920px;
    margin: 0 auto;
  }
}
.t-block-content h2 {
  font-size: 28px;
  color: var(--page-color);
  margin-bottom: 22px;
}
@media (min-width: 992px) {
  .t-block-content h2 {
    font-size: 32px;
  }
}
.t-block-content p:last-child {
  margin-bottom: 0;
}

.t-gallery {
  padding: 0 20px;
  margin: 47px 0 75px;
}
@media (min-width: 768px) {
  .t-gallery {
    padding: 0 4.1428571429%;
  }
}
@media (min-width: 992px) {
  .t-gallery {
    margin: 113px 0 100px;
  }
}

.t-gallery-list {
  margin-bottom: -30px;
  justify-content: center;
}
@media (min-width: 992px) {
  .t-gallery-list {
    margin-right: -30px;
    margin-left: -30px;
    margin-bottom: -60px;
  }
  .t-gallery-list > .col,
.t-gallery-list > [class*=col-] {
    padding-right: 30px;
    padding-left: 30px;
  }
}
.t-gallery-list__item {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .t-gallery-list__item {
    margin-bottom: 60px;
  }
}
.t-gallery-list__item figure {
  margin-bottom: 0;
  height: 0;
  padding-bottom: 118.4782608696%;
  border-radius: 18px;
}
@media (min-width: 992px) {
  .t-gallery-list__item figure {
    border-radius: 27px;
  }
}
.t-gallery-list__item:nth-child(5n+1) {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .t-gallery-list__item:nth-child(5n+1) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.t-gallery-list__item:nth-child(5n+1) figure {
  padding-bottom: 53.6507936508%;
}
@media (min-width: 768px) {
  .t-gallery-list__item:nth-child(5n+1) figure {
    padding-bottom: 0;
    height: 100%;
  }
}

.t-map {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .t-map {
    margin-top: 68px;
  }
}
.t-map__image img {
  width: 100%;
}
.t-map__more {
  margin-top: 10px;
}
.t-map__more a {
  display: inline-block;
  font-size: 15px;
  padding-bottom: 3px;
  border-bottom: thin solid currentColor;
}

.t-stores {
  padding-left: 20px;
  padding-right: 20px;
  margin: 40px 0;
}
@media (min-width: 768px) {
  .t-stores {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 992px) {
  .t-stores {
    margin: 83px 0 63px;
  }
}
@media (min-width: 992px) {
  .t-stores__row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .t-stores__row > .col,
.t-stores__row > [class*=col-] {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 767.98px) {
  .t-stores__header {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .t-stores__content {
    max-width: 444px;
  }
}
.t-stores__title {
  font-size: 36px;
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .t-stores__title {
    font-size: 56px;
  }
}
.t-stores__description {
  font-size: 18px;
}
@media (min-width: 992px) {
  .t-stores__description {
    font-size: 24px;
  }
}
.t-stores__more {
  margin-top: 43px;
}

.t-stores-list__item:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 18px;
  border-bottom: thin solid #000000;
}
.t-stores-list__title {
  font-size: 28px;
  margin-bottom: 5px;
}
@media (min-width: 992px) {
  .t-stores-list__title {
    font-size: 32px;
  }
}
.t-stores-list__description {
  font-size: 18px;
}
@media (min-width: 992px) {
  .t-stores-list__description {
    font-size: 24px;
  }
}

.t-banner-image {
  height: 280px;
}
@media (min-width: 768px) {
  .t-banner-image {
    height: 755px;
  }
}

.t-banner-wide {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 65.2631578947vh;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 768px) {
  .t-banner-wide {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 768px) {
  .t-banner-wide {
    min-height: 396px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.t-banner-wide::before {
  content: "";
  display: block;
  background-color: #000000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.t-banner-wide__wrapper {
  max-width: 692px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.t-banner-wide__logo {
  margin-bottom: 10px;
}
.t-banner-wide__logo img {
  height: 74px;
}
.t-banner-wide__description {
  font-size: 28px;
}
@media (min-width: 992px) {
  .t-banner-wide__description {
    font-size: 32px;
  }
}

.t-group-buttons {
  margin: 40px 0;
  text-align: center;
}
@media (min-width: 992px) {
  .t-group-buttons {
    margin: 58px 0;
  }
}
.t-group-buttons .t-btn:not(:last-child) {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .t-group-buttons .t-btn:not(:last-child) {
    margin-bottom: 0;
    margin-right: 36px;
  }
}
.t-group-buttons .t-btn-primary {
  background-color: var(--page-color);
}
.t-group-buttons .t-btn-link {
  color: var(--page-color);
}

.t-block-content {
  padding-left: 20px;
  padding-right: 20px;
  margin: 65px 0 47px;
  font-size: 18px;
}
@media (min-width: 768px) {
  .t-block-content {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 992px) {
  .t-block-content {
    margin: 74px 0 113px;
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .t-block-content__wrapper {
    max-width: 920px;
    margin: 0 auto;
  }
}
.t-block-content h2 {
  font-size: 28px;
  color: var(--page-color);
  margin-bottom: 22px;
}
@media (min-width: 992px) {
  .t-block-content h2 {
    font-size: 32px;
  }
}
.t-block-content p:last-child {
  margin-bottom: 0;
}

.t-gallery {
  padding: 0 20px;
  margin: 47px 0 75px;
}
@media (min-width: 768px) {
  .t-gallery {
    padding: 0 4.1428571429%;
  }
}
@media (min-width: 992px) {
  .t-gallery {
    margin: 113px 0 100px;
  }
}

.t-gallery-list {
  margin-bottom: -30px;
  justify-content: center;
}
@media (min-width: 992px) {
  .t-gallery-list {
    margin-right: -30px;
    margin-left: -30px;
    margin-bottom: -60px;
  }
  .t-gallery-list > .col,
.t-gallery-list > [class*=col-] {
    padding-right: 30px;
    padding-left: 30px;
  }
}
.t-gallery-list__item {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .t-gallery-list__item {
    margin-bottom: 60px;
  }
}
.t-gallery-list__item figure {
  margin-bottom: 0;
  height: 0;
  padding-bottom: 118.4782608696%;
  border-radius: 18px;
}
@media (min-width: 992px) {
  .t-gallery-list__item figure {
    border-radius: 27px;
  }
}
.t-gallery-list__item:nth-child(5n+1) {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .t-gallery-list__item:nth-child(5n+1) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.t-gallery-list__item:nth-child(5n+1) figure {
  padding-bottom: 53.6507936508%;
}
@media (min-width: 768px) {
  .t-gallery-list__item:nth-child(5n+1) figure {
    padding-bottom: 0;
    height: 100%;
  }
}

.t-banner-media {
  position: relative;
  overflow: hidden;
  min-height: 76.0526315789vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
  background-color: #000000;
  color: #ffffff;
}
@media (min-width: 768px) {
  .t-banner-media {
    margin-bottom: 20px;
    min-height: 500px;
  }
}
.t-banner-media__video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.t-banner-media__video::before {
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.63);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.t-banner-media__video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.t-banner-media__content {
  position: relative;
  z-index: 2;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 28px;
  padding-bottom: 28px;
}
@media (min-width: 768px) {
  .t-banner-media__content {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 768px) {
  .t-banner-media__content {
    padding-top: 82px;
    padding-bottom: 82px;
  }
}
.t-banner-media__title {
  max-width: 560px;
  font-size: 38px;
  line-height: 1;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .t-banner-media__title {
    font-size: 76px;
    margin-bottom: 50px;
  }
}
.t-banner-media__description {
  max-width: 217px;
  font-size: 24px;
}
.t-banner-media__more .t-btn-link {
  margin-left: 30px;
  margin-top: 12px;
}
@media (min-width: 576px) {
  .t-banner-media__more .t-btn-link {
    margin-top: 0;
  }
}

.t-block-cta {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 45px;
  background-color: #000000;
  color: #ffffff;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .t-block-cta {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 768px) {
  .t-block-cta {
    margin-bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .t-block-cta__header {
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .t-block-cta__wrapper {
    max-width: 836px;
  }
}
.t-block-cta__content {
  font-size: 28px;
}
@media (min-width: 992px) {
  .t-block-cta__content {
    font-size: 32px;
  }
}
.t-block-cta__content h2 {
  font-size: 32px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .t-block-cta__content h2 {
    font-size: 48px;
  }
}
.t-block-cta__content p:last-child {
  margin-bottom: 0;
}
.t-block-cta__body {
  display: flex;
  flex-direction: column;
}
.t-block-cta__description {
  font-size: 14px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .t-block-cta__description {
    max-width: 217px;
    margin-top: 24px;
  }
}
.t-block-cta__call {
  margin-top: auto;
}
.t-block-cta__more {
  margin-top: 20px;
}
.t-block-cta--wide {
  background-color: #f6f6f6 !important;
  color: #000000 !important;
}
@media (min-width: 992px) {
  .t-block-cta--wide .t-block-cta__wrapper {
    max-width: 920px;
    margin: 0 auto;
  }
}
.t-block-cta--wide .t-block-cta__content h2 {
  color: var(--page-color);
}
.t-block-cta--wide .t-block-cta__more {
  margin-top: 60px;
  text-align: center;
}
.t-block-cta--dynamic {
  background-color: var(--page-color);
}

.t-block-text {
  margin: 43px 0 55px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 28px;
  line-height: 1.08;
}
@media (min-width: 768px) {
  .t-block-text {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 992px) {
  .t-block-text {
    font-size: 32px;
  }
}
.t-block-text a {
  text-decoration: underline;
}
.t-block-text a:hover {
  color: #c01e64;
}

.t-banner-normal {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 48px;
  padding-bottom: 48px;
  color: #ffffff;
  margin-bottom: 10px;
  min-height: 70.6578947368vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .t-banner-normal {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 768px) {
  .t-banner-normal {
    min-height: 460px;
    margin-bottom: 20px;
  }
}
.t-banner-normal__wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .t-banner-normal__wrapper {
    max-width: 483px;
  }
}
@media (min-width: 992px) {
  .t-banner-normal__content {
    transition: all 0.4s cubic-bezier(0.5, 1, 0.89, 1);
  }
}
.t-banner-normal__logo {
  margin-bottom: 32px;
}
.t-banner-normal__logo img {
  height: 74px;
}
.t-banner-normal__description {
  font-size: 19px;
}
@media (min-width: 992px) {
  .t-banner-normal__description {
    font-size: 24px;
  }
}
.t-banner-normal__more {
  margin-top: 35px;
}
@media (min-width: 992px) {
  .t-banner-normal__more {
    margin-top: 90px;
  }
  .t-banner-normal__more:hover + div {
    transform: translate(0, 30px);
  }
}

.t-diagram {
  padding-top: 66px;
  padding-bottom: 83px;
  background-color: #c01e64;
}
.t-diagram__header {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 62px;
}
@media (min-width: 768px) {
  .t-diagram__header {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (min-width: 1200px) {
  .t-diagram__header {
    max-width: 940px;
  }
}
.t-diagram__title {
  font-size: 32px;
  margin-bottom: 16px;
}
.t-diagram__description {
  font-size: 24px;
}
.t-diagram__body {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
@media (min-width: 768px) {
  .t-diagram__body {
    padding-left: 4.1428571429%;
    padding-right: 4.1428571429%;
  }
}
@media (max-width: 991.98px) {
  .t-diagram__body {
    max-width: 355px;
    margin-left: auto;
    margin-right: auto;
  }
}
.t-diagram__body .row {
  margin-right: -30px;
  margin-left: -30px;
}
.t-diagram__body .row > .col,
.t-diagram__body .row > [class*=col-] {
  padding-right: 30px;
  padding-left: 30px;
}
@media (max-width: 991.98px) {
  .t-diagram__body .row + .row {
    margin-top: 23px;
  }
}
@media (min-width: 992px) {
  .t-diagram__body .row + .row .t-diagram-card {
    margin-bottom: 0;
  }
}

.t-diagram-card {
  position: relative;
  margin-bottom: 60px;
}
@media (max-width: 991.98px) {
  .t-diagram-card {
    width: 184px;
    margin-left: 22px;
    margin-bottom: 30px;
  }
}
.t-diagram-card__inner {
  background-color: #c01e64;
  border: thin solid #000000;
  border-radius: 27px;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  overflow: hidden;
  height: 327px;
  padding: 40px 20px;
}
@media (max-width: 991.98px) {
  .t-diagram-card__inner {
    height: 100px;
    border-radius: 23px;
    padding: 10px 20px;
    justify-content: center;
  }
}
.t-diagram-card__header {
  flex: 1;
}
@media (max-width: 991.98px) {
  .t-diagram-card__header {
    flex: 0 0 auto;
  }
}
.t-diagram-card__header figure {
  margin-bottom: 18px;
}
@media (max-width: 991.98px) {
  .t-diagram-card__header figure {
    margin-bottom: 9px;
  }
}
.t-diagram-card__header figure img {
  max-height: 35px;
}
@media (max-width: 991.98px) {
  .t-diagram-card__header figure img {
    max-height: 26px;
  }
}
.t-diagram-card__header p {
  font-size: 18px;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .t-diagram-card__header p {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .t-diagram-card__header p span {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .t-diagram-card__footer {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .t-diagram-card--root {
    width: 260px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 66px;
  }
  .t-diagram-card--root .t-diagram-card__inner {
    height: 140px;
  }
}
@media (max-width: 991.98px) {
  .t-diagram-card--small {
    width: 197px;
    margin-left: auto;
  }
}
.t-diagram-card--small .t-diagram-card__inner {
  height: 165px;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .t-diagram-card--small .t-diagram-card__inner {
    height: 90px;
  }
}
.t-diagram-card--small .t-diagram-card__inner h3 {
  font-size: 32px;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .t-diagram-card--small .t-diagram-card__inner h3 {
    font-size: 24px;
  }
}
.t-diagram-card--small .t-diagram-card__inner.lazy {
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  background-position: 50% 50%;
}
.t-diagram-card--small .t-diagram-card__inner.lazy::before {
  content: "";
  display: block;
  background-color: #000000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.52;
}

@media (min-width: 992px) {
  .t-diagram-information {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.t-diagram-information ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 14px;
  max-width: 125px;
}
.t-diagram-information ul li {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.t-diagram-information ul li:not(:last-child) {
  border-bottom: thin solid rgba(0, 0, 0, 0.2);
}
.t-diagram-information ul li figure {
  margin-bottom: 0;
  margin-right: 20px;
}
.t-diagram-information ul li figure img {
  width: 19px;
}
.t-diagram-information ul li:first-child {
  padding-top: 0;
}
.t-diagram-information ul li:last-child {
  padding-bottom: 0;
}
.t-diagram-information .t-btn {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .t-diagram-information .t-btn {
    margin-top: auto;
  }
}
@media (min-width: 991px) and (max-width: 1400px) {
  .t-diagram-information .t-btn {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
  }
  .t-diagram-information .t-btn svg[role=img] {
    margin-left: 10px;
  }
}

.t-diagram-path {
  position: relative;
}
@media (max-width: 991.98px) {
  .t-diagram-path {
    display: none;
  }
}
.t-diagram-path img {
  position: absolute;
  top: -13px;
  left: -13px;
  height: 27px;
}
.t-diagram-path--1 {
  width: 81.40625%;
  margin: 0 auto;
}
.t-diagram-path--2 {
  width: 39px;
  position: absolute;
  top: 100%;
  left: 37.6811594203%;
}
.t-diagram-path--3 {
  width: 175px;
  position: absolute;
  top: calc(100% - 27px);
  right: calc(100% - 27px);
}
.t-diagram-path--4 {
  width: 88px;
  position: absolute;
  top: 100%;
  right: 0;
}
.t-diagram-path--5 {
  width: 128px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

.t-diagram-line-mobile {
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translate(-50%, 0);
  height: 664px;
}
.t-diagram-line-mobile img {
  position: absolute;
  top: -10px;
  left: -10px;
}
.t-diagram-line-mobile svg {
  height: 100%;
}

.t-diagram-arrow {
  position: absolute;
  top: calc(100% + 15px);
  right: 48px;
}
@media (min-width: 992px) {
  .t-diagram-arrow {
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
}