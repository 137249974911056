/*---------------------------------
**** Objects | Buttons
-----------------------------------*/
.t-btn {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    background-color: transparent;
    border: thin solid transparent;
    border-radius: 60px;
    padding: 10px 30px;
    transition: all 0.15s ease-in-out;
    appearance: none;
    font-size: 18px;
    font-weight: bold;
    @include lh-small;
    letter-spacing: 0.105em;
    text-transform: uppercase;
	cursor: pointer;
    color: #fff;

    @include icon-size(10px) {
        margin-left: 50px;
        transition: all 0.15s ease-in-out;
    };

    &:hover {
        color: #fff;

        svg[role="img"] {
            transform: rotate(180deg);
        }
    }
}

%btn-disabled {
    &:disabled,
    &.disabled {
        background-color: $gray-400;

        &:hover {
            background-color: $gray-400;
        }
    }
}

%btn-link-disabled {
    &:disabled,
    &.disabled {
        color: $gray-400;

        &:hover {
            color: $gray-400;
        }
    }
}

.t-btn-block {
    width: 100%;
}

.t-btn-link {
	border-width: 0 0 2px;
    border-color: currentColor;
    border-radius: 0;
    padding: 5px 0;
    font-weight: normal;
    text-transform: none;
    color: inherit;

    @extend %btn-link-disabled;

    &:hover {
        color: inherit;
    }
}

.t-btn-primary {
    background-color: $primary;

    &:hover {
        background-color: $primary;
    }

    @extend %btn-disabled;
}

.t-btn-outline-primary {
    border-color: $primary;
    color: $primary;

    &:hover {
        background-color: $primary;
        color: #fff;
    }

    @extend %btn-disabled;
}

.t-btn-white {
    background-color: #ffffff;
    color: #000000;

    &:hover {
        background-color: #ffffff;
        color: #000000;
    }

    @extend %btn-disabled;
}