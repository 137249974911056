// Core
@import "_core/styles/functions";
@import "_core/styles/variables";
@import "_core/styles/mixins";
@import "_core/styles/fonts";

/* ==========================================================================
## GLOBAL
========================================================================== */
@import "global.scss";
@import "objects/buttons.scss";
@import "objects/common.scss";
@import "objects/form.scss";
@import "objects/icons.scss";
@import "objects/loading.scss";
@import "objects/type.scss";

/* ==========================================================================
## COMPONENTS
========================================================================== */
@import "components/BlockContact/block-contact.scss";
@import "components/Footer/footer.scss";
@import "components/FormContact/form-contact.scss";
@import "components/Header/header.scss";

/* ==========================================================================
## PAGES
========================================================================== */
@import "pages/Chalupa/chalupa.scss";
@import "pages/Chickenjoint/chickenjoint.scss";
@import "pages/Contact/contact.scss";
@import "pages/Difdoner/difdoner.scss";
@import "pages/Index/index.scss";
@import "pages/WholeSale/whole-sale.scss";