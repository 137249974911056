// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Breakpoint
$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
) !default;

// Typography
$font-family-primary: "HK Grotesk", sans-serif !default;

// Colors
$gray-100: #f6f6f6 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$blue: #007bff !default;
$red: #dc3545 !default;
$pink: #c01e64 !default;
$brown: #5c311d !default;
$green: #73b53d !default;
$blue: #1d62af !default;

$primary: #000000 !default;
$light: $gray-100;
$danger: $red !default;
$body-color: #000000 !default;

// Form
$custom-control-size: 18px !default;
$custom-control-dot-size: 10px !default;
$custom-control-gap: 10px !default;
$custom-control-font-size: 16px !default;
$custom-control-font-weight: normal !default;
$custom-control-line-height: 1.2 !default;
$custom-control-border-color: $body-color !default;
$custom-control-top: 0 !default;

// Easing
$easeInOutQuint: cubic-bezier(0.83, 0, 0.17, 1);
$easeOutQuint: cubic-bezier(0.22, 1, 0.36, 1);
$easeInOutCubic: cubic-bezier(0.65, 0, 0.35, 1);
$easeOutCubic: cubic-bezier(0.33, 1, 0.68, 1);
$easeInOutQuad: cubic-bezier(0.45, 0.05, 0.55, 0.95);

// Padding
$px: (58/1400*100%);
