.t-form-control {
    display: block;
    width: 100%;
    appearance: none;
    background-color: transparent;
    border-width: 0 0 thin;
    border-color: #000000;
    border-style: solid;
    border-radius: 0;
    padding: 10px 0;
    font-size: 22px;
    @include lh-small;

    &::placeholder {
        color: rgba($color: #000000, $alpha: 0.4);
    }
}

.t-form-group {
    margin-bottom: 20px;
}

.t-custom-control {
    display: block;
    padding-left: $custom-control-size + $custom-control-gap;
    position: relative;
    line-height: 1;

    label {
        font-size: $custom-control-font-size;
        font-weight: $custom-control-font-weight;
        line-height: $custom-control-line-height;
        vertical-align: middle;
        margin-bottom: 0;
        cursor: pointer;
        user-select: none;

        &::before {
            content: "";
            display: block;
            width: $custom-control-size;
            height: $custom-control-size;
            border: thin solid $custom-control-border-color;
            position: absolute;
            top: $custom-control-top;
            left: 0;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    input {
        display: none;

        &[type="checkbox"] {
            &:checked + label:before {
                background-color: $custom-control-border-color;
                background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.5L3.77551 6.33333L9 1' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E%0A");
                background-size: 12px auto;
            }
        }

        &[type="radio"] {
            & + label {
                &::before {
                    border-radius: 50%;
                }
            }

            &:checked + label:before {
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='black'/%3E%3C/svg%3E%0A");
                background-size: 12px auto;
            }
        }
    }

    a {
        color: $blue;
    }
}
