/*---------------------------------
**** Objects | Icons
-----------------------------------*/
svg[role="img"] {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    fill: currentColor;
}

.t-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: all 0.2s ease;
}

.t-icon-minus,
.t-icon-plus {
    font-size: 7px;
    width: 1em;
    height: 1em;

    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
    }
}

.t-icon-plus {
    position: relative;

    &::after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background-color: currentColor;
        position: absolute;
        top: 0;
        left: calc((1em - 1px) / 2);
    }
}

.t-icon-remove {
    width: 1em;
    height: 1em;
    position: relative;

    &::before,
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        position: absolute;
        left: 0;
        top: 50%;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}