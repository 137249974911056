.t-map {
    margin-top: 40px;

    @include breakpoint-up(lg) {
        margin-top: 68px;
    }

    &__image {
        img {
            width: 100%;
        }
    }

    &__more {
        margin-top: 10px;

        a {
            display: inline-block;
            font-size: 15px;
            padding-bottom: 3px;
            border-bottom: thin solid currentColor;
        }
    }
}

.t-stores {
    @include padding-x;
    margin: 40px 0;

    @include breakpoint-up(lg) {
        margin: 83px 0 63px;
    }

    &__row {
        @include breakpoint-up(lg) {
            @include gutters(60px);
        }
    }

    &__header {
        @include breakpoint-down(md) {
            margin-bottom: 40px;
        }
    }

    &__content {
        @include breakpoint-up(lg) {
            max-width: 444px;
        }
    }

    &__title {
        font-size: 36px;
        margin-bottom: 15px;

        @include breakpoint-up(lg) {
            font-size: 56px;
        }
    }

    &__description {
        font-size: 18px;

        @include breakpoint-up(lg) {
            font-size: 24px;
        }
    }

    &__more {
        margin-top: 43px;
    }
}

.t-stores-list {
    &__item {
        &:not(:last-child) {
            padding-bottom: 20px;
            margin-bottom: 18px;
            border-bottom: thin solid #000000;
        }
    }

    &__title {
        font-size: 28px;
        margin-bottom: 5px;

        @include breakpoint-up(lg) {
            font-size: 32px;
        }
    }

    &__description {
        font-size: 18px;

        @include breakpoint-up(lg) {
            font-size: 24px;
        }
    }
}

.t-banner-image {
    height: 280px;

    @include breakpoint-up(md) {
        height: 755px;
    }
}