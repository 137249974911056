.t-footer {
    background-color: $light;
    @include padding-x;
    padding-top: 44px;
    padding-bottom: 44px;
    margin-bottom: 10px;

    @include breakpoint-up(md) {
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 20px;
    }

    .page-template-contact & {
        background-color: transparent;
    }
}

.t-footer-nav {
    list-style: none;
    padding-left: 0;
    margin: 0 -10px -34px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include lh-small;
    white-space: nowrap;

    li {
        padding: 0 10px;
        margin-bottom: 34px;
    }
}