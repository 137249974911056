.t-form-contact {
    &__header {
        font-size: 28px;
        margin-bottom: 20px;

        @include breakpoint-up(lg) {
            font-size: 32px;
            margin-bottom: 40px;
        }

        h5 {
            color: $blue;
        }
    }

    &__footer {
        > div:not(:last-child) {
            @include breakpoint-down(lg) {
                margin-bottom: 37px;
            }
        }

        .t-btn {
            @include breakpoint-down(lg) {
                width: 100%;
            }
        }
    }
}